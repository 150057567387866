/* eslint-disable react/no-danger */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import artworkUtils from 'tools/artwork';

import { saveSettings } from 'actions/UserActions';

import ConfirmationModal from 'components/Modal/ConfirmationModal';

import CommunSVGs from 'svgs/commun';

type Props = {
  isMe: Boolean,
  profile: Object,
};

export default function UserAbout({ profile, isMe }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('user');

  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState();

  const handleRemove = () => {
    const params = {
      aboutContent: null,
      coverFile: null,
    };
    dispatch(saveSettings(params, undefined, undefined, 'about'));
  };

  return (
    <div className="User-about">
      <div className="text-center">
        <img
          src={
            profile.coverFile
              ? artworkUtils.getFileUrl(profile.coverFile, 360)
              : '/assets/images/default-about-cover.png'
          }
          alt="WIP"
        />
      </div>
      <div
        className="User-about-content"
        dangerouslySetInnerHTML={{ __html: profile.aboutContent || t('about-default-text') }}
      />
      <div className="User-about-actions">
        {isMe && (
          <>
            {(profile.aboutContent || profile.coverFile) && (
              <div className="User-about-icon cursor" role="presentation">
                <CommunSVGs.Bin
                  width="20px"
                  height="20px"
                  fill="var(--primary)"
                  onClick={() => setShowRemoveConfirmation(true)}
                />
              </div>
            )}
            <div className="User-about-icon">
              <Link to="/profile/about/edit">
                <CommunSVGs.Edit width="20px" height="20px" fill="var(--primary)" />
              </Link>
            </div>
          </>
        )}
      </div>
      {showRemoveConfirmation && (
        <ConfirmationModal
          title={t('modal-remove-about-title')}
          text={t('modal-remove-about-text')}
          buttonLabel="Remove now"
          onCancel={() => setShowRemoveConfirmation(false)}
          onConfirm={handleRemove}
        />
      )}
    </div>
  );
}
